import React, { FC } from 'react';

const LaunchShareModule: FC = () => {
  return (
    <div className="bcg launch__guide-share">
      <div className="container--xl ma p15">
        <div className="container--m ma ac">
          <h3>
            {`Space is for everyone. Here’s a link to share the launch with your friends.`}
          </h3>
        </div>
        <div className="ac">
          <a
            href="#0"
            data-social="facebook"
            className="caps btn btn__white m05 btn--outline small"
          >
            Facebook
          </a>
          <a
            href="#0"
            data-social="twitter"
            className="caps btn btn__white m05 btn--outline small"
          >
            Twitter
          </a>
        </div>
      </div>
    </div>
  );
};

export default LaunchShareModule;
