import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import { GlossaryTerm, Launch } from 'superclient';
import { useSiteState } from '../state/site-state-context';
import MetaModule from '../components/core/meta-module';
import LaunchGuide from '../components/launches/launch-guide';
import LaunchTimer from '../components/launches/launch-timer';
import LaunchShareModule from '../components/launches/launch-share-module';
import LaunchBlock from '../components/launches/launch-block';
import LaunchNextModule from '../components/launches/launch-next-module';
import LaunchVideoLink from '../components/launches/launch-video-link';
import { highlightGlossaryTerms } from '../components/core/glossary-highlighter';
import AstronautCardList from '../components/astronauts/astronaut-card-list';
import { Superclient } from '../clients/superclient';

const TemplateLaunch: FC<{
  pageContext: {
    launch: Launch;
    activeLaunch?: Launch;
    nextLaunch?: Launch;
    glossaryTerms: GlossaryTerm[];
  };
}> = ({ pageContext }) => {
  const {
    launch: _launch,
    activeLaunch: _activeLaunch,
    nextLaunch,
    glossaryTerms
  } = pageContext;

  const { nextLaunch: activeLaunch } = useSiteState();

  const { launch } = Superclient.useLaunch(_launch.slug.current, _launch);
  const editorial = useRef<HTMLDivElement>(null);

  const isActiveLaunch =
    activeLaunch?._id === launch?._id || _activeLaunch?._id === launch?._id;

  const launchSite = (launch?.launchModules || []).filter(
    (site) => site.module && site.module._type === 'site'
  )[0];

  useEffect(() => {
    setTimeout(() => {
      highlightGlossaryTerms(editorial.current || undefined, glossaryTerms);
    }, 1000);
  }, [glossaryTerms]);

  const showLivestreamLink =
    launch?.launchInfo.livestreamProvider &&
    launch.launchInfo.livestreamProvider.length > 0 &&
    launch.launchInfo.livestreamUrl &&
    launch.launchInfo.livestreamUrl.length > 0;

  return (
    <div className="launch">
      {showLivestreamLink && (
        <LaunchVideoLink
          provider={launch?.launchInfo.livestreamProvider}
          url={launch?.launchInfo.livestreamUrl}
        />
      )}
      <MetaModule
        title={launch?.title || ''}
        path={`/launches/${launch?.slug.current}`}
        description={launch?.launchInfo.launchDescription}
        sanityImage={launch?.image}
      />
      <div
        className={classNames('', {
          bcg: !isActiveLaunch,
          bcy: isActiveLaunch
        })}
      >
        {launch && <LaunchTimer launch={launch} />}
      </div>
      <AstronautCardList
        title={'Crew'}
        astronauts={launch.astronauts}
        tbdAstronauts={launch.tbdAstronauts}
      />
      <div className="" ref={editorial}>
        {launch?.launchInfo.launchDate &&
          (launch?.launchModules || []).map((module) => {
            return (
              <LaunchBlock
                key={module._key}
                launchModule={module}
                launchDate={new Date(launch!.launchInfo.launchDate!.utc)}
              />
            );
          })}
      </div>
      {launch && launchSite && (
        <React.Fragment>
          {(launchSite.module.launchPad ||
            (launchSite.module.launchLat && launchSite.module.launchLong)) && (
            <LaunchGuide launch={launch} launchModule={launchSite} />
          )}
          {launchSite.module.know && (
            <div className="container--xl ma p15">
              <div className="container--l">
                <h6 className="caps">Know Before You Go</h6>
                <BlockContent blocks={launchSite.module.know} />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      <LaunchShareModule />
      {nextLaunch && <LaunchNextModule launch={nextLaunch} />}
    </div>
  );
};

export default TemplateLaunch;
