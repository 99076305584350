import React, { FC } from 'react';

const LaunchVideoLink: FC<{ provider: string; url: string }> = ({
  provider,
  url
}) => {
  return (
    <div className="bcg">
      <div className="container--xl ma p15">
        <div
          className="h3"
          style={{ width: '50vw', minWidth: '250px', marginBottom: '0.75em' }}
        >
          {`This livestream is presented on a platform which cannot be embedded in third-party apps. You can watch the launch courtesy of ${provider} at the link below.`}
        </div>
        <div className="mb05">
          <a
            href={url}
            target="blank"
            alt="Livestream Link"
            className="bcy caps h6"
            style={{ padding: '0.75em 1em' }}
          >
            PLAY LIVE STREAM
          </a>
        </div>
      </div>
    </div>
  );
};

export default LaunchVideoLink;
