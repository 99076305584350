import React, { FC, useState } from 'react';
import cx from 'classnames';
import { Agency, Nation } from 'superclient';
import ImageSanity from './image-sanity';
import MouseOver from './mouse-over';
import { useWindowSize } from '../../state/use-window-size';

const AgencyNationGrid: FC<{ agencies: Agency[]; nations: Nation[] }> = ({
  agencies,
  nations
}) => {
  const windowSize = useWindowSize();
  const [mouseOverNation, setMouseOverNation] = useState(-1);
  const [mouseOverAgency, setMouseOverAgency] = useState(-1);

  const totalCount = (agencies || []).length + (nations || []).length;
  const stacked = totalCount > 4;

  const iconHeight = windowSize.width > 475 ? 35 : 22;

  return totalCount > 0 ? (
    <div className={cx('mt1 f', { fr: !stacked, fc: stacked })}>
      <div className={cx('')}>
        <div className="x pb1 h5 caps">Nations</div>
        <div className="x f fr fw">
          {nations &&
            nations.map((nation, i) => {
              return nation.flag && nation.flag.asset ? (
                <div
                  key={nation._id}
                  className="rel mb1 f fr jcc mr1"
                  style={{
                    width:
                      iconHeight * nation.flag.asset.metadata.dimensions.aspectRatio,
                    height: iconHeight
                  }}
                  onMouseEnter={() => setMouseOverNation(i)}
                  onMouseLeave={() => setMouseOverNation(-1)}
                >
                  <ImageSanity
                    className="abs x y top left"
                    image={nation.flag}
                    alt={nation.name}
                    height={iconHeight}
                    ratioMultipler={4}
                  />
                  <MouseOver
                    textColor="white"
                    backgroundColor="#241F21"
                    text={nation.name}
                    className={cx('abs mt1', {
                      hide: mouseOverNation !== i
                    })}
                    style={{
                      marginTop: iconHeight
                    }}
                  />
                </div>
              ) : (
                <div />
              );
            })}
        </div>
      </div>

      <div className={cx('', { pl2: !stacked, mt1: stacked })}>
        <div className="x pb1 h5 caps">Agencies</div>
        <div className="x f fr fw">
          {agencies &&
            agencies.map((agency, i) => {
              return agency.icon && agency.icon.asset ? (
                <div
                  key={agency._id}
                  className="rel mb1 f fr jcc mr1"
                  style={{
                    width:
                      iconHeight * agency.icon.asset.metadata.dimensions.aspectRatio,
                    height: iconHeight
                  }}
                  onMouseEnter={() => setMouseOverAgency(i)}
                  onMouseLeave={() => setMouseOverAgency(-1)}
                >
                  <ImageSanity
                    className="abs x y top left"
                    image={agency.icon}
                    alt={agency.name}
                    height={iconHeight}
                    ratioMultipler={4}
                  />
                  <MouseOver
                    text={agency.name}
                    textColor="white"
                    backgroundColor="#241F21"
                    className={cx('abs', {
                      hide: mouseOverAgency !== i
                    })}
                    style={{
                      marginTop: iconHeight
                    }}
                  />
                </div>
              ) : (
                <div key={agency._id} />
              );
            })}
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default AgencyNationGrid;
