import React, { useState, useEffect, useRef, FC, useMemo } from 'react';
import classNames from 'classnames';
import YouTubePlayer from 'youtube-player';
import { DateTime } from 'luxon';
import { deriveLaunchDescription, Launch, useCountdownToLaunch } from 'superclient';
import AgencyNationGrid from '../core/agency-nation-grid';
import { TwitterTweetEmbed } from 'react-twitter-embed';

const TweetPlaceholder = () => (
  <div className="launch_tweet_placeholder">
    <button
      className="launch_tweet_placeholder__tweet"
      onClick={() => {
        window.open('https://x.com/spacex', '_blank');
      }}
    >
      <div className="launch_tweet_placeholder__header">
        <div className="launch_tweet_placeholder__avatar" />
        <div className="launch_tweet_placeholder__header_text">
          <div>SpaceX</div>
          <div>@spacex</div>
        </div>
        <div>
          <svg viewBox="0 0 24 24" className="launch_tweet_placeholder__x_logo">
            <g>
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
            </g>
          </svg>
        </div>
      </div>
      <div className="launch_tweet_placeholder__body">Livestream via SpaceX</div>
      <div className="launch_tweet_placeholder__card">
        <div className="launch_tweet_placeholder__image">
          <svg
            viewBox="0 0 1920 1124"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="1920" height="1124" fill="black" />
            <g clipPath="url(#clip0_26_2)">
              <path
                d="M702.022 540.114H587.362L583.114 543.311V645.971H614.855V607.474L617.885 604.742H702.027C723.272 604.742 733.232 598.989 733.232 584.228V560.656C733.228 545.871 723.272 540.114 702.022 540.114ZM702.022 578.068C702.022 585.022 697.451 586.467 687.491 586.467H618.232L614.851 583.158V561.167L617.881 558.377H687.491C697.451 558.377 702.022 559.805 702.022 566.792V578.068Z"
                fill="white"
              />
              <path
                d="M805.112 559.533L832.424 599.808L830.911 603.709H770.509L755.09 622.799H845.001L851.232 626.618L865.191 645.996H901.515L821.896 538.801"
                fill="white"
              />
              <path
                d="M1152.3 625.145V596.651L1155.56 593.812H1216.33V575.668H1120.02V645.992H1262.09V627.935H1155.71"
                fill="white"
              />
              <path
                d="M1263.59 540.114H1120.02V560.064H1263.59V540.114Z"
                fill="white"
              />
              <path
                d="M959.894 559.006H1075.56C1073.77 544.204 1064.65 540.097 1041.5 540.097H958.663C932.54 540.097 924.208 545.229 924.208 565.125V620.947C924.208 640.864 932.54 645.996 958.663 645.996H1041.5C1064.99 645.996 1073.98 641.556 1074.59 626.202H959.894L956.492 622.98V561.162"
                fill="white"
              />
              <path
                d="M505.934 581.364H421.793L419.315 578.619V560.504L421.78 558.586H535.21L536.759 554.772C532.896 544.768 522.89 540.101 504.311 540.101H425.517C399.411 540.101 391.066 545.233 391.066 565.13V576.833C391.066 596.754 399.411 601.878 425.517 601.878H509.471L512.108 604.207V623.145L509.958 626.099H413.926V626.009H388.772C388.772 626.009 385.894 627.461 386.004 628.14C388.151 642.058 397.571 646 420.124 646H505.934C532.037 646 540.631 640.868 540.631 620.951V606.371C540.631 586.487 532.037 581.364 505.934 581.364Z"
                fill="white"
              />
              <path
                d="M1353.9 539.677H1306.67L1304.11 544.587L1356.56 583.067C1366.49 577.277 1377.3 571.409 1389.06 565.619"
                fill="white"
              />
              <path
                d="M1396.22 612.165L1442.32 646H1490.12L1492.1 641.519L1423.26 590.808C1414.12 597.561 1405.09 604.692 1396.22 612.165Z"
                fill="white"
              />
              <path
                d="M1346.87 645.922H1304.14L1300.53 640.231C1329.58 611.997 1459.53 490.967 1742 478C1742 478 1504.91 486.049 1346.87 645.922Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_26_2">
                <rect
                  width="1356"
                  height="168"
                  fill="white"
                  transform="translate(386 478)"
                />
              </clipPath>
            </defs>
          </svg>
          <div>Watch on X</div>
        </div>
        <div className="launch_tweet_placeholder__card_details">
          <div>SpaceX</div>
          <div>Livestream</div>
        </div>
      </div>
    </button>
  </div>
);

const LaunchTimer: FC<{ launch: Launch }> = ({ launch }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(null);

  const playerRef = useRef<HTMLDivElement>(null);

  const countdownComponents = useCountdownToLaunch(launch);

  const [tweetWidth, setTweetWidth] = useState(300);

  const {
    youtubeVideoId,
    overrideLaunchDate,
    overrideLaunchTime
  } = launch.launchInfo;

  const isPending =
    (overrideLaunchDate !== undefined && overrideLaunchDate.length > 0) ||
    (overrideLaunchTime !== undefined && overrideLaunchTime.length > 0);

  useEffect(() => {
    const timer = document.querySelector('.js-launch-timer');
    if (youtubeVideoId !== undefined && youtubeVideoId.length > 0) {
      setShowVideo(true);
      if (timer) timer.classList.remove('hidden');
      if (videoPlayer) {
        videoPlayer.playVideo();
      }
    }
  }, [youtubeVideoId]);

  useEffect(() => {
    const player = playerRef.current.querySelector('.js-video');
    if (player && launch.launchInfo.youtubeVideoId) {
      const thePlayer = YouTubePlayer(player, {
        videoId: launch.launchInfo.youtubeVideoId
      });
      setVideoPlayer(thePlayer);
    }
  }, [launch]);

  useEffect(() => {
    const sharable = require('sharable/browser');
    sharable.default();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const tweetDiv = document.querySelector('.launch_tweet_split');
      if (tweetDiv) {
        const width = tweetDiv.getBoundingClientRect().width;
        setTweetWidth(width);
      }
    };

    handleResize();
    setTimeout(handleResize, 100);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const description = useMemo(
    () => deriveLaunchDescription(launch, countdownComponents),
    [launch, countdownComponents]
  );

  const timeText = useMemo(() => {
    const date = DateTime.fromISO(launch.launchInfo.launchDate.utc).plus({
      seconds: countdownComponents?.launchSeconds || 0
    });
    const time = countdownComponents?.launchSeconds
      ? date.toFormat(`h:mm:ss a ZZZZ '(UTC 'Z')'`)
      : date.toFormat(`h:mm a ZZZZ '(UTC 'Z')'`);

    return overrideLaunchTime && overrideLaunchTime.length > 0
      ? overrideLaunchTime
      : time;
  }, [launch, countdownComponents]);

  const dateText = useMemo(() => {
    const date = DateTime.fromISO(launch.launchInfo.launchDate.utc);
    return overrideLaunchDate && overrideLaunchDate.length > 0
      ? overrideLaunchDate
      : date.toFormat('DDDD');
  }, []);

  return (
    <div className="relative" ref={playerRef}>
      {launch.launchInfo.xPostId && (
        <>
          <style>
            {`
      .launch_tweet_split > div {
        width: ${tweetWidth}px !important;
        margin: 0 auto;
      }
      `}
          </style>
          <div className="launch_tweet_above">
            <TwitterTweetEmbed
              key={launch.launchInfo.xPostId}
              tweetId={launch.launchInfo.xPostId}
            />
          </div>
        </>
      )}
      {launch.launchInfo.xPostExpected && !launch.launchInfo.xPostId && (
        <div className="launch_tweet_above">
          <TweetPlaceholder />
        </div>
      )}
      <div className="container--xl ma p15">
        {/* Video Logic Handling */}
        {showVideo && videoPlayer && <div className="nav-push x" />}
        <div
          className={classNames('launch__video', {
            visible: (showVideo || countdownComponents?.isPast) && videoPlayer
          })}
        >
          <div className="js-video" />
        </div>

        <div
          className="launch__time_container"
          data-has-x-post={
            launch.launchInfo.xPostId !== undefined ||
            launch.launchInfo.xPostExpected
          }
        >
          <div className="f fc">
            {/* Render the timers */}
            {!countdownComponents?.isPast && isPending && (
              <div>
                <h1
                  className="launch__time super m0 p0 f js-time"
                  data-has-x-post={
                    launch.launchInfo.xPostId !== undefined ||
                    launch.launchInfo.xPostExpected
                  }
                >
                  <div className="launch__time_num rel">
                    PENDING
                    <span className="xsmall caps bau abs left bottom">
                      Launch Time
                    </span>
                  </div>
                </h1>
              </div>
            )}
            {!countdownComponents?.isPast && !isPending && !showVideo && (
              <h1
                className="launch__time super m0 p0 f js-time"
                data-has-x-post={
                  launch.launchInfo.xPostId !== undefined ||
                  launch.launchInfo.xPostExpected
                }
              >
                <div className="launch__time_num rel">
                  {countdownComponents?.dayString}
                  <span className="xsmall caps bau abs left bottom">
                    {countdownComponents?.days === 1 ? 'Day' : 'Days'}
                  </span>
                </div>
                :
                <div className="launch__time_num rel">
                  {countdownComponents?.hourString}
                  <span className="xsmall caps bau abs left bottom">
                    {countdownComponents?.hours === 1 ? 'Hour' : 'Hours'}
                  </span>
                </div>
                :
                <div className="launch__time_num rel">
                  {countdownComponents?.minuteString}
                  <span className="xsmall caps bau abs left bottom">
                    {countdownComponents?.minutes === 1 ? 'Minute' : 'Minutes'}
                  </span>
                </div>
                :
                <div className="launch__time_num rel">
                  {countdownComponents?.secondString}
                  <span className="xsmall caps bau abs left bottom">
                    {countdownComponents?.seconds === 1 ? 'Second' : 'Seconds'}
                  </span>
                </div>
              </h1>
            )}
            {countdownComponents?.isPast && !showVideo && (
              <h1
                className="launch__time super m0 p0 f js-time"
                data-has-x-post={
                  launch.launchInfo.xPostId !== undefined ||
                  launch.launchInfo.xPostExpected
                }
              >
                <div className="launch__time_num rel">LAUNCHED</div>
              </h1>
            )}
            <p className="launch__description">{description}</p>
            <AgencyNationGrid agencies={launch.agencies} nations={launch.nations} />
            <div className="f1" />
            <div className="f fw jcb aie launch__time-share">
              <div className="launch__time-actual mt1">
                <h6 className="xsmall bold akkura caps mr15 pr15">
                  Date: {dateText}{' '}
                </h6>
                <h6 className="xsmall bold akkura caps mb0">Time: {timeText}</h6>
              </div>
            </div>
          </div>

          {launch.launchInfo.xPostExpected && !launch.launchInfo.xPostId && (
            <div className="launch_tweet_split" style={{ paddingBottom: '56%' }}>
              <TweetPlaceholder />
            </div>
          )}
          {launch.launchInfo.xPostId && (
            <div className="launch_tweet_split" style={{ paddingBottom: '56%' }}>
              <TwitterTweetEmbed
                key={launch.launchInfo.xPostId}
                tweetId={launch.launchInfo.xPostId}
              />
            </div>
          )}
        </div>
      </div>

      <div className="launch__share">
        <div className="mxa px15 pb15 container--xl s12 caps">
          <span className="mr1 small">Share</span>
          <a
            href="#0"
            data-url={`https://www.supercluster.com/launches/${launch.slug.current}`}
            data-social="twitter"
          >
            <svg
              width="22px"
              height="22px"
              viewBox="0 0 13 13"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-3"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="sc-header_2020-06-10_A"
                  transform="translate(0.5, 0.5), scale(0.0103)"
                  fill="#000000"
                  fillRule="nonzero"
                >
                  <path
                    d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                    fill="black"
                    id="twitter-icon"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
          <a
            href="#0"
            data-url={`https://www.supercluster.com/launches/${launch.slug.current}`}
            className="ml1"
            data-social="facebook"
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LaunchTimer;
