import React, { FC } from 'react';
import { Link } from 'gatsby';
import { Launch } from 'superclient';

const LaunchNextModule: FC<{ launch: Launch }> = ({
  launch: {
    launchInfo: { launchDescription },
    slug
  }
}) => {
  return (
    <div className="bcg x">
      <div className="container--xl ma p15">
        <div className="f jcb aic py15">
          <h6 className="caps p0 m0">Another Launch</h6>
          <Link to="/launches" className="caps f aic h6">
            See All Launches{' '}
            <span className="arrow__right arrow ml05 block cb rel" />
          </Link>
        </div>
        <div className="container--m py1">
          <Link to={`/launches/${slug.current}`} className="h3 mb1 block">
            {launchDescription}
          </Link>
          <div>
            <Link to={`/launches/${slug.current}`} className="caps f aic h6">
              Learn More <span className="arrow__right arrow ml05 block cb rel" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchNextModule;
