import React, { FC, useState, useEffect } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import cx from 'classnames';
import { AutoSizer } from 'react-virtualized';
import BlockContent from '@sanity/block-content-to-react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Launch, LaunchModule } from 'superclient';

const LaunchGuide: FC<{
  launch: Launch;
  launchModule: LaunchModule;
}> = ({ launch, launchModule: { module: launchSite } }) => {
  const [activePin, setActivePin] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 40.761058,
    longitude: -73.9785,
    zoom: 12
  });
  const [settings, setSettings] = useState({
    scrollZoom: false,
    cluserMaxZoom: 18,
    clusterRadius: 50
  });

  const zoomMap = (direction) => {
    setViewport({
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      zoom: direction ? viewport.zoom + 1 : viewport.zoom - 1
    });
  };

  const setActive = (index) => {
    console.log('active');
    setActivePin(index);
  };

  useEffect(() => {
    setViewport({
      latitude: launchSite.launchPad
        ? launchSite.launchPad.lat
        : parseFloat(launchSite.launchLat),
      longitude: launchSite.launchPad
        ? launchSite.launchPad.lng
        : parseFloat(launchSite.launchLong),
      zoom: 10
    });
  }, [launchSite]);

  return (
    <div className="bcb">
      <div className="container--xl ma p1">
        <h3 className="cw">{`Here's where to view ${launch.title}`}</h3>
      </div>
      <div className="bcb">
        <div className="">
          <div className="launch__guide-map">
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <div className="">
                    <div className="launch__guide-zoom abs z1 right top">
                      <div
                        className="x abs block f jcc cw aic launch__guide-zoom-plus"
                        onClick={() => zoomMap(true)}
                      >
                        <span />
                      </div>
                      <div
                        className="x abs block f jcc cw aic launch__guide-zoom-minus"
                        onClick={() => zoomMap(false)}
                      >
                        <span />
                      </div>
                    </div>
                    <ReactMapGL
                      {...viewport}
                      height={height}
                      width={width}
                      {...settings}
                      mapboxApiAccessToken="pk.eyJ1Ijoic3VwZXItY2x1c3RlciIsImEiOiJjanFwcnU1MW0wNHBoM3htdGZpbzZyMGN2In0.TKByLttHzjuw3koldSEWOA"
                      mapStyle="mapbox://styles/super-cluster/cjqprv7gt0cur2spagosfm4eo"
                      onViewportChange={(viewport) => setViewport(viewport)}
                    >
                      <div className="launch__guide-key ac abs top left p1 bcb cw">
                        <h6 className="caps xsmall m0 p05 mb1">Key</h6>
                        <div className="launch__guide-key--pin rel mt15 cy p05" />
                        <h6 className="xsmall m0 mb1 p05">Launch Site</h6>
                        <div className="launch__guide-key--pin rel mt15 cdb p05" />
                        <h6 className="xsmall m0 mb1 p05">Paid</h6>
                        <div className="launch__guide-key--pin rel mt15 cbl p05" />
                        <h6 className="xsmall m0 mb1 p05">Free</h6>
                      </div>
                      {launchSite.launchPad && (
                        <Marker
                          latitude={launchSite.launchPad.lat}
                          longitude={launchSite.launchPad.lng}
                        >
                          <div className="launch__guide-pin">
                            <span className="launch__guide-pin-pad block abs" />
                          </div>
                        </Marker>
                      )}

                      {launchSite.launchLat && launchSite.launchLong && (
                        <Marker
                          latitude={parseFloat(launchSite.launchLat)}
                          longitude={parseFloat(launchSite.launchLong)}
                        >
                          <div className="launch__guide-pin">
                            <span className="launch__guide-pin-pad block abs" />
                          </div>
                        </Marker>
                      )}
                      {(launchSite.viewingLocations || []).map((location, i) => {
                        return (
                          <Marker
                            key={location._key}
                            latitude={location.location.lat}
                            longitude={location.location.lng}
                            className={activePin === i ? 'z10' : ''}
                          >
                            <div
                              className={cx('launch__guide-pin rel cw', {
                                active: activePin === i
                              })}
                              onClick={() => setActive(i)}
                            >
                              <div
                                className={cx(
                                  'launch__guide-pin_info cb bcw abs bottom left'
                                )}
                              >
                                <div className="p1">
                                  <h6 className="caps m0 p0 mb15 pb1 small b">
                                    {location.name}
                                  </h6>
                                  <div className="f jcs">
                                    <h6 className="xxsmall mr15 b m0 p0 caps">
                                      Cost: {location.cost}
                                    </h6>
                                    <h6 className="xxsmall b m0 p0 caps">
                                      Hours: {location.hours}
                                    </h6>
                                  </div>
                                  <div className="small">
                                    <BlockContent blocks={location.description} />
                                  </div>
                                </div>
                              </div>
                              <span
                                className={cx('launch__guide-pin-icon', {
                                  cdb: location.type === 'paid',
                                  cbl: location.type === 'free'
                                })}
                              />
                            </div>
                          </Marker>
                        );
                      })}
                    </ReactMapGL>
                  </div>
                );
              }}
            </AutoSizer>
          </div>
        </div>
        {launchSite.viewingLocations && (
          <div className="container--xl ma p15 cw">
            <h6 className="caps m0 py15">Viewing Sites</h6>
            <ul className="f jcb fw launch__guide-list pb15">
              {(launchSite.viewingLocations || []).map((location, i) => (
                <li
                  className="small caps under"
                  key={location._key}
                  onClick={() => setActive(i)}
                >
                  {location.name}
                </li>
              ))}
              <li />
              <li />
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default LaunchGuide;
